import { clearCookies, clearStorages, signOut } from "../auth/utils";
import { useAuthContext } from "../context/AuthContext";

const useLog = () => {
  const auth = useAuthContext();

  const log = (message) => {
    process.env.REACT_APP_BASE_URL.includes("dev") && console.log(message);
  };

  const extendedLog = (message, className, functionName) => {
    process.env.REACT_APP_BASE_URL.includes("dev") &&
      console.log(`[${className}.js] - ${functionName} => `, message);
  };

  const errorLog = async (error, className, functionName, queryName) => {
    const errorMessage = error?.message || error;
    if (process.env.REACT_APP_BASE_URL.includes("dev")) {
      console.error(
        `[${className}.js] - ${functionName} ${queryName} Error => `,
        JSON.stringify(errorMessage)
      );
    }

    if (
      JSON.stringify(errorMessage)?.includes("Token verification failed") ||
      JSON.stringify(errorMessage)?.includes("Token verification error") ||
      JSON.stringify(errorMessage)?.includes("Unauthorized") ||
      JSON.stringify(errorMessage)?.includes("Error during authentication")
    ) {
      // shutdown Intercom
      if (Intercom) {
        Intercom("shutdown");
      }

      try {
        // Sign out from Firebase
        await signOut();

        // clear lS and SS
        clearStorages();

        // clear cookies
        clearCookies();

        // Perform logout via auth context
        auth.logout();
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }
  };

  return { log, extendedLog, errorLog };
};

export default useLog;
