import React from "react";
import Table from "../../../Components/Table/Table";
import UserInvitationsTableRow from "./UserInvitationsTableRow";
import { useTranslation } from "../../../context/Internationalization";

const schema = (t) => [
  {
    id: 'from',
    label: t('from').charAt(0).toUpperCase() + t('from').slice(1),
  },
  {
    id: 'to',
    label: t('to').charAt(0).toUpperCase() + t('to').slice(1),
  },
  {
    id: 'referenceTo',
    label: t('referenceTo'),
  },
  {
    id: 'status',
    label: t('status'),
  },
  {
    id: 'type',
    label: t('type'),
  },
]

const UserInvitationsTable = ({ data, menuActions = [], lastElementRef }) => {
  const { t } = useTranslation();
  const rowElement = (dato, index) => <UserInvitationsTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema(t)} rowElement={rowElement}/>);
};

export default UserInvitationsTable;
