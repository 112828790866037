export const INVITATION_FIELDS = `
fragment invitationFields on Invitation {
  _id
  fromId
  invitationType
  invitationToType
  toId
  context {
    message
    itemId
    itemType
  }
  status
}
`;

export const GET_INVITATIONS = `
  ${INVITATION_FIELDS}
   query Invitations($skip: Int, $limit: Int) {
    invitations(skip: $skip, limit: $limit) {
      ...invitationFields
    }
  }
`;

export const RESPOND_INVITATION = `
${INVITATION_FIELDS}
mutation RespondInvitation($invitationId: String!, $outcome: Boolean!) {
  acceptRejectInvitation(invitationId: $invitationId, outcome: $outcome) {
    ...invitationFields
  }
}
`;