import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  memo,
} from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { css } from "@emotion/css";
import { AuthContext } from "../../../context/AuthContext";
import { SnackBarContext } from "../../../context/SnackBarContext";
import IconButton from "@mui/material/IconButton";
import {
  CREATE_CLUB_SA,
  EDIT_CLUB,
  IS_PREMIUM,
  REMOVE_CLUB_ADMIN_SA,
} from "../SuperAdminService";
import { GET_RESOLVE_USERS } from "../../../graphql/userGraphql";
import { DELETE_IMAGE, UPLOAD_IMAGE } from "../../Teams/TeamsService";
import Form from "../../../Components/Form/Form";
import { formValidator, isEmpty } from "../../../Components/Form/validators";
import TextFieldRenderer from "../../../Components/Form/TextFieldRenderer";
import ToggleRenderer from "../../../Components/Form/ToggleRenderer";
import SelectRenderer from "../../../Components/Form/SelectRenderer";
import Stack from "@mui/material/Stack";
import DeletePlayerConnectionIcon from "../../../assets/images/Svg/DeletePlayerConnectionIcon";
import { makeStyles } from "@mui/styles";
import { colors } from "../../../theme/colors";
import { fonts } from "../../../theme/fonts";
import RemoveClubAdminConfirmationModal from "./RemoveClubAdminConfirmationModal";
import LastClubAdminInformationModal from "./LastClubAdminInformationModal";
import {
  brandColorsOptions,
  countries,
  FILE_TYPES,
  imageMimeTypesDropzone,
  sports,
  USER_ROLES,
} from "../../../utility/Enum";
import { useTranslation } from "../../../context/Internationalization";
import { axiosGet, axiosPost } from "../../../api";
import DrawerFormTitle from "../../../Components/Common/DrawerFormTitle/DrawerFormTitle";
import useGraphql from "../../../Hooks/useGraphql";
import useLog from "../../../Hooks/useLog";
import { getStorage } from "../../../auth/utils";
import MultipleFilesUpload from "../../../Components/Form/MultipleFilesUpload";

const useStyles = makeStyles({
  phoneContainer: {
    height: "auto",
    width: "100%",
    margin: "8px 0",
    padding: "8px 16px",
    background: colors.Base.White,
    border: `1px solid ${colors.Grey[300]}`,
    boxShadow: `0px 1px 2px rgba(22, 31, 37, 0.05)`,
    borderRadius: "8px",
  },
  phoneComponentName: {
    ...fonts.text.md.normal,
    color: colors.Grey[900],
  },
  phoneComponentNumber: {
    ...fonts.text.md.normal,
    color: colors.Grey[500],
  },
  sectionTitle: {
    ...fonts.text.sm.medium,
    color: colors.Grey[700],
  },
});

const DrawerFormStyles = getStorage().getItem("impersonate")
  ? css`
      .wrapper {
        width: 100%;
        max-width: 400px;
        margin-top: 24px;
        padding: 10px 20px;
      }
    `
  : css`
      .wrapper {
        width: 100%;
        max-width: 400px;
        padding: 10px 20px;
      }
    `;

const schema = (t, roles, sport) => [
  {
    label: t("club_name"),
    name: "name",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    label: t("abbreviation"),
    name: "abbreviation",
    maxLength: 3,
    renderer: TextFieldRenderer,
    row: 2,
  },
  {
    label: t("founded"),
    name: "founded",
    renderer: TextFieldRenderer,
    row: 3,
  },
  {
    label: t("country"),
    name: "country",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    options:
      countries?.map((country) => ({
        value: country.label,
        text: country.label,
      })) || [],
    renderer: SelectRenderer,
    row: 4,
  },
  {
    label: t("sport"),
    name: "sport",
    options: sports(t),
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    row: 5,
    renderer: SelectRenderer,
  },
  {
    label: t("brand_color"),
    name: "brandColor",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    renderer: SelectRenderer,
    options: brandColorsOptions(t) || [],
    colorPicker: true,
    row: 6,
  },
  roles.includes(USER_ROLES.SUPER_ADMIN)
    ? {
        label: t("isFC"),
        name: "isFC",
        renderer: ToggleRenderer,
        row: 7,
        hidden: sport?.toLowerCase() !== "football",
      }
    : {},
  roles.includes(USER_ROLES.SUPER_ADMIN)
    ? {
        label: t("isPremium"),
        name: "isPremium",
        renderer: ToggleRenderer,
        row: 8,
      }
    : {},
  roles.includes(USER_ROLES.SUPER_ADMIN) ||
  roles.includes(USER_ROLES.CLUB_ADMIN)
    ? {
        label: "",
        name: "clubLogo",
        renderer: MultipleFilesUpload,
        singleFileUpload: true,
        acceptedFileTypes: imageMimeTypesDropzone,
        row: 9,
      }
    : {},
  // {
  //   label: 'Location',
  //   name: 'location',
  //   disabled: selectedClub,
  //   renderer: LocationRenderer,
  //   row: 10
  // },
];

const initialState = {
  name: "",
  abbreviation: "",
  founded: "",
  country: "Greece",
  sport: "",
  isPremium: false,
  isFC: false,
  brandColor: "#1B2028",
  clubLogo: null,
  initialClubLogo: null,
  location: undefined,
  clubAdminUsers: [],
};

const populateData = (club) => {
  const _initialState = { ...initialState };
  if (club) {
    _initialState.name = club.name;
    _initialState.abbreviation = club.abbreviation || "";
    _initialState.founded = club.founded || "";
    _initialState.country = club.country || "";
    _initialState.sport = club.sport || "";
    _initialState.isPremium = club.isPremium;
    _initialState.clubLogo = club.logo ? [club.logo] : null;
    _initialState.initialClubLogo = _initialState.clubLogo;
    _initialState.isFC = club.isFC;
    _initialState.location = {
      address: club.location?.address,
      lat: club.location?.coordinates[1],
      lng: club.location?.coordinates[0],
    };
    _initialState.clubAdminUsers = club.clubAdminUsers;
  }

  return _initialState;
};

const ClubsDrawer = ({ openDrawer, onCloseDrawer, selectedClub, getClubs }) => {
  const { authState } = useContext(AuthContext);
  const snackBar = useContext(SnackBarContext);
  const clubId = authState.user.club?._id;
  const [values, setValues] = useState(populateData(selectedClub));
  const [removeModal, setRemoveModal] = useState(false);
  const [lastAdminModal, setLastAdminModal] = useState(false);
  const { t } = useTranslation();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();
  const roles = authState.user.roles;
  const _schema = schema(t, roles, values?.sport);

  const styles = useStyles();

  useEffect(() => {
    if (selectedClub) {
      setValues(populateData(selectedClub));
      getThemeColor(selectedClub._id);
    } else {
      setValues(initialState);
    }
  }, [selectedClub?._id]);

  const getThemeColor = (clubId) => {
    axiosGet(`/theme/${clubId}`)
      .then((response) => {
        const { primaryColor } = response.data;
        setValues((prevState) => ({ ...prevState, brandColor: primaryColor }));
      })
      .catch((error) => {
        errorLog(error, "ClubsDrawer", "getThemeColor", "theme");
        snackBar.setOptions({
          alertMessage: error.response?.data?.error || error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const onClose = () => {
    onCloseDrawer();
  };

  const handleSubmit = () => {
    const errors = formValidator({ schema: _schema, values });
    if (Object.keys(errors).length > 0) {
      setValues((prevState) => ({ ...prevState, errors }));
    } else {
      let query = CREATE_CLUB_SA;
      let variables = {
        name: values.name,
        abbreviation: values.abbreviation,
        founded: +values.founded,
        country: values.country,
        sport: values.sport,
        timezone: "Europe/Athens",
        isFC: values.isFC,
        // location: {
        //   type: "Point",
        //   address: values.location.address,
        //   coordinates: [values.location.lng, values.location.lat],
        // }
      };

      if (selectedClub) {
        query = EDIT_CLUB;
        variables = roles.includes(USER_ROLES.SUPER_ADMIN)
          ? {
              clubId: selectedClub._id,
              name: values.name,
              abbreviation: values.abbreviation || "",
              founded: +values.founded || 0,
              country: values.country || "",
              sport: values.sport,
              isPremium: values.isPremium ? "true" : "false",
              isFC: values.isFC,
              timezone: "Europe/Athens",
            }
          : {
              clubId: selectedClub._id,
              name: values.name,
              abbreviation: values.abbreviation || "",
              founded: +values.founded || 0,
              country: values.country || "",
              sport: values.sport,
              isPremium: values.isPremium ? "true" : "false",
              timezone: "Europe/Athens",
            };
      }

      axiosGraphQL({ query, variables })
        .then((response) => {
          if (query === CREATE_CLUB_SA) {
            isPremiumRoutine(
              response.data.data.createClubSA._id,
              values.isPremium
            );
          } else {
            setThemeColor(selectedClub._id, values.brandColor);
          }
        })
        .catch((error) => {
          errorLog(
            error,
            "ClubsDrawer",
            "handleSubmit",
            Object.keys({ query })[0]
          );
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }
  };

  const isPremiumRoutine = (clubId, isPremium) => {
    axiosGraphQL({
      query: IS_PREMIUM,
      variables: { clubId, isPremium },
    })
      .then((_res) => {
        setThemeColor(clubId, values.brandColor);
      })
      .catch((error) => {
        errorLog(error, "ClubsDrawer", "isPremiumRoutine", "IS_PREMIUM");
        snackBar.setOptions({
          alertMessage: error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const setThemeColor = (clubId, selectedBrandColor) => {
    axiosPost(`/theme/${clubId}`, { primaryColor: selectedBrandColor })
      .then(async () => await uploadClubLogo(clubId))
      .catch((error) => {
        errorLog(error, "ClubsDrawer", "setThemeColor", "theme");
        snackBar.setOptions({
          alertMessage: error.response?.data?.error || error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const uploadClubLogo = async (clubId) => {
    let alertMessage = t("academyCreatedSuccessfully");
    if (selectedClub._id) alertMessage = t("academyUpdatedSuccessfully");

    if (values.initialClubLogo && values.initialClubLogo.length > 0) {
      const shouldBeDeleted =
        values.clubLogo.length === 0 ||
        (values.clubLogo[0].name !== undefined &&
          values.clubLogo[0].name !==
            values.initialClubLogo[0].split("/").pop());
      if (shouldBeDeleted) {
        const deleteVariables = {
          deleteType: FILE_TYPES.clubLogo,
          itemId: clubId,
          fileName: values.initialClubLogo[0].split("/").pop(),
        };
        axiosGraphQL({
          query: DELETE_IMAGE,
          variables: deleteVariables,
        })
          .then(async () => {
            (roles.includes(USER_ROLES.SUPER_ADMIN) ||
              roles.includes(USER_ROLES.CLUB_ADMIN)) &&
              values.clubLogo.length === 0 &&
              (await getClubs());
          })
          .catch((error) => {
            errorLog(error, "ClubsDrawer", "uploadClubLogo", "DELETE_IMAGE");
            snackBar.setOptions({
              alertMessage: t("error_uploading_image"),
              alertSeverity: "error",
            });
            snackBar.setSnackbarOpen(true);
          });
      }
    }

    if (
      values.clubLogo &&
      values.clubLogo.length > 0 &&
      values.clubLogo[0].name
    ) {
      const variables = {
        uploadType: FILE_TYPES.clubLogo,
        itemId: clubId,
        fileName: values.clubLogo[0].name,
      };
      axiosGraphQL({ query: UPLOAD_IMAGE, variables })
        .then((response) => {
          const reader = new FileReader();
          reader.onload = function () {
            const arrayBuffer = this.result;
            const array = new Uint8Array(arrayBuffer);
            const blob = new Blob([array]);

            fetch(response.data.data.uploadImage, {
              method: "PUT",
              body: blob,
            })
              .then(async () => {
                snackBar.setOptions({
                  alertMessage,
                  alertSeverity: "success",
                });
                snackBar.setSnackbarOpen(true);
                await getClubs();
                onClose();
              })
              .catch((error) => {
                errorLog(
                  error,
                  "ClubsDrawer",
                  "uploadClubLogo",
                  "UPLOAD_IMAGE"
                );
                snackBar.setOptions({
                  alertMessage: t("error_uploading_image"),
                  alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
              });
          };
          reader.readAsArrayBuffer(values.clubLogo[0]);
        })
        .catch((error) => {
          errorLog(error, "ClubsDrawer", "uploadClubLogo", "UPLOAD_IMAGE");
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    } else {
      snackBar.setOptions({
        alertMessage,
        alertSeverity: "success",
      });
      snackBar.setSnackbarOpen(true);
      await getClubs();
      onClose();
    }
  };

  const onChange = ({ target: { name, value } }) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRemoveClubAdmin = useCallback(() => {
    axiosGraphQL({
      query: REMOVE_CLUB_ADMIN_SA,
      variables: {
        clubId: selectedClub._id,
        userId: removeModal?._id,
        deleteUser: false,
      },
    })
      .then(async (_res) => {
        snackBar.setOptions({
          alertMessage: t("club_admin_successfully_removed"),
          alertSeverity: "success",
        });
        snackBar.setSnackbarOpen(true);
        closeModals();
        await getClubs();
        onClose();
      })
      .catch((error) => {
        errorLog(
          error,
          "ClubsDrawer",
          "handleRemoveClubAdmin",
          "REMOVE_CLUB_ADMIN_SA"
        );
        snackBar.setOptions({
          alertMessage: error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  }, [selectedClub?._id, removeModal?._id]);

  const openRemoveModal = (user) => {
    setRemoveModal(user);
  };

  const closeModals = () => {
    setRemoveModal(false);
    setLastAdminModal(false);
  };

  const openLastAdminModal = (user) => {
    setLastAdminModal(user);
  };

  return (
    <Drawer anchor="right" open={openDrawer} onClose={onClose}>
      <RemoveClubAdminConfirmationModal
        open={removeModal}
        onClose={closeModals}
        onRemove={handleRemoveClubAdmin}
      />
      <LastClubAdminInformationModal
        open={lastAdminModal}
        onClose={closeModals}
      />
      <Box className={DrawerFormStyles}>
        <Box className="wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={onClose}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <DrawerFormTitle
              actionText={selectedClub ? t("update") : t("create")}
              className="grid-container-header"
              text={t("create_club")}
            />
            <Grid item xs={12}>
              <Form
                schema={_schema}
                values={values}
                onChange={onChange}
                errors={values.errors}
              />
              {selectedClub &&
              Object.entries(selectedClub).length > 0 &&
              roles.includes(USER_ROLES.SUPER_ADMIN) ? (
                <Grid item xs={12} style={{ paddingTop: "24px" }}>
                  <span className={styles.sectionTitle}>
                    {t("club_admins")}
                  </span>
                  {values.clubAdminUsers?.map((userId) => (
                    <PhoneComponents
                      userId={userId}
                      clubId={clubId}
                      onRemoveClubAdmin={
                        values.clubAdminUsers.length === 1
                          ? openLastAdminModal
                          : openRemoveModal
                      }
                      axiosGraphQL={axiosGraphQL}
                      errorLog={errorLog}
                      snackBar={snackBar}
                    />
                  ))}
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Button onClick={handleSubmit} fullWidth>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

const PhoneComponents = memo(
  ({ userId, onRemoveClubAdmin, axiosGraphQL, errorLog, snackBar }) => {
    const styles = useStyles();
    const [user, setUser] = useState({
      _id: userId,
      firstName: "",
      lastName: "",
      phone: "",
    });

    useEffect(() => {
      axiosGraphQL({
        query: GET_RESOLVE_USERS,
        variables: { ids: [userId] },
      })
        .then((response) => {
          const { resolveUserIds } = response.data.data;
          setUser(resolveUserIds[0]);
        })
        .catch((error) => {
          errorLog(error, "ClubsDrawer", "useEffect", "GET_RESOLVE_USERS");
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }, []);

    const handleRemoveClubAdmin = useCallback(() => {
      onRemoveClubAdmin(user);
    }, [user]);

    if (!user) {
      return null;
    }

    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        className={styles.phoneContainer}
      >
        <Grid container spacing={1}>
          <Grid item className={styles.phoneComponentName}>
            {user?.firstName} {user?.lastName}
          </Grid>
          <Grid item className={styles.phoneComponentNumber}>
            {user?.mobile}
          </Grid>
        </Grid>
        <IconButton color={"neutral"} onClick={handleRemoveClubAdmin}>
          <DeletePlayerConnectionIcon />
        </IconButton>
      </Stack>
    );
  }
);

export default ClubsDrawer;
