import React from "react";
import { Stack, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import useStyles from "./formStyles";

const TextFieldRenderer = ({
  label,
  name,
  onChange,
  disabled,
  value,
  style = {},
  error = "",
  placeholder,
  type = "text",
  min = undefined,
  max,
  maxLength = undefined,
  startAdornment = undefined,
  endAdornment = undefined,
  styles = useStyles(),
  isFromGeolocation,
  ...rest
}) => {
  return (
    <Stack direction={"column"} spacing={0.5} sx={{ width: "100%" }}>
      <span className={styles.label}>{label}</span>

      {name === "mobile" ? (
        <MuiTelInput
          disabled={disabled}
          defaultCountry="GR"
          onlyCountries={["GR", "CY"]}
          value={value}
          onChange={(newValue) =>
            onChange({ target: { name, value: newValue } })
          }
          error={!!error}
          helperText={error}
          fullWidth
          size="small"
          variant="outlined"
          InputProps={{
            disableunderline: "true",
            variant: "outlined",
            classes: { notchedOutline: styles.input },
            style: { ...style, height: "40px" },
            ref: rest?.InputProps?.ref,
          }}
        />
      ) : (
        <TextField
          {...rest}
          name={name}
          InputProps={{
            ref: rest?.InputProps?.ref,
            style: { ...style, height: "40px" },
            startAdornment,
            endAdornment,
            classes: { notchedOutline: styles.input },
            autoComplete: rest?.autoComplete || "on",
          }}
          inputProps={{
            ref: rest?.inputProps?.ref,
            onChange: rest?.inputProps?.onChange,
            style: { height: "40px", paddingBottom: "18px" },
            min,
            max,
            maxLength,
          }}
          style={{ ...style, height: "40px" }}
          onChange={onChange}
          type={type}
          min={min}
          max={max}
          disabled={disabled}
          error={!!error}
          placeholder={placeholder}
          FormHelperTextProps={{
            classes: {
              root: isFromGeolocation
                ? styles.helperLocationText
                : styles.helperText,
            },
          }}
          helperText={error}
          value={value}
          fullWidth
        />
      )}
    </Stack>
  );
};

export default TextFieldRenderer;
