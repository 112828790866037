import { createTheme } from "@mui/material/styles";
import PlayFontBold from "../assets/font/Play-Bold.ttf";
import PlayFontRegular from "../assets/font/Play-Regular.ttf";
import { elGR } from "@mui/material/locale";

import { colors } from "./colors";
import { fonts } from "./fonts";

const { Primary, Error, Warning, Success, Grey, Base } = colors;
const { display, text } = fonts;

export const playerDexTheme = createTheme(
  {
    typography: {
      fontFamily: [
        "Play",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      primary: {
        main: Primary[500],
      },
      error: {
        main: Error[500],
      },
      warning: {
        main: Warning[500],
      },
      success: {
        main: Success[500],
      },
      grey: {
        main: Grey[500],
      },
      neutral: {
        main: Base.White,
      },
    },
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            transition: "all 0.3s ease-out",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            background: Primary[600],
            border: `1px solid ${Primary[600]}`,
            borderRadius: "8px",
            padding: "10px 16px",
            color: Base.White,
            ":focus": {
              background: Primary[600],
              boxShadow: `none`,
            },
            ":hover": {
              background: Primary[700],
            },
            ":disabled": {
              background: Primary[200],
            },
            ...text.sm.normal,
          },
          outlinedPrimary: {
            background: Grey[100],
            borderRadius: "8px",
            padding: "10px 16px",
            border: "none",
            textTransform: "none",
            color: Grey[600],
            ":focus": {
              background: Grey[200],
              boxShadow: `none`,
            },
            ":hover": {
              background: Grey[200],
              border: "none",
            },
            ":disabled": {
              background: Grey[200],
            },
            ...text.sm.normal,
          },
          outlinedError: {
            background: Error[600],
            borderRadius: "8px",
            padding: "10px 16px",
            border: `1px solid ${Error[300]}`,
            textTransform: "none",
            color: Base.White,
            ":focus": {
              background: Error[600],
              boxShadow: `none`,
            },
            ":hover": {
              background: Error[700],
              border: `1px solid ${Error[700]}`,
            },
            ":disabled": {
              background: Error[500],
            },
            ...text.sm.normal,
          },
          outlinedWarning: {
            background: Warning[600],
            borderRadius: "8px",
            padding: "10px 16px",
            border: `1px solid ${Warning[300]}`,
            textTransform: "none",
            color: Base.White,
            ":focus": {
              background: Warning[600],
              boxShadow: `none`,
            },
            ":hover": {
              background: Warning[700],
              border: `1px solid ${Warning[700]}`,
            },
            ":disabled": {
              background: Warning[500],
            },
            ...text.sm.normal,
          },
          onlyTextPrimary: {
            backgroundColor: "transparent",
            color: Primary[700],
            fontWeight: 700,
            border: 0,
            padding: 0,
            ":hover": {
              background: "transparent",
              border: 0,
            },
            ":focus": {
              background: "transparent",
              border: 0,
            },
          },
          onlyTextError: {
            backgroundColor: "transparent",
            color: Error[700],
            padding: "10px 16px",
            border: 0,
            ":hover": {
              background: "transparent",
              border: 0,
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: Grey[200],
            color: Grey[500],
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: Grey[500],
            ...text.xs.medium,
            ":focus": {
              color: Grey[500],
            },
            ":active": {
              color: Grey[500],
            },
            active: {
              color: Grey[500],
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h5: {
            color: Grey[900],
            ...display.sm.medium,
          },
          h6: {
            color: Grey[700],
            ...text.sm.medium,
          },
          subtitle2: {
            color: Grey[500],
            ...text.sm.normal,
            maxWidth: 200,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          subtitle1: {
            color: Grey[700],
            ...text.sm.normal,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: Grey[700],
            ...text.sm.normal,
            ":hover": {
              backgroundColor: Grey[50],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: Grey[700],
            ...text.sm.normal,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${Grey[100]}`,
            boxShadow: `0px 12px 16px -4px rgba(22, 31, 37, 0.08), 0px 4px 6px -2px rgba(22, 31, 37, 0.03)`,
            borderRadius: "8px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            width: "100%",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Play';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${PlayFontRegular}) format('truetype');
        }
        @font-face {
          font-family: 'Play';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${PlayFontBold}) format('truetype');
        }
        main {
          width: 100%;
          min-width: 320px;
        }
        .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
            color: ${Grey[500]}
        }
        .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
        color: ${Grey[500]}
        }
        /* Custom scrollbar styles */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        ::-webkit-scrollbar-track {
          background: ${Grey[200]};
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: ${Grey[500]};
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: ${Grey[600]};
        }
        /* For Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: ${Grey[500]} ${Grey[200]};
        }
      `,
      },
    },
  },
  elGR
);
