import React, {useEffect, useState, useContext} from "react";
import PropTypes from "prop-types";
import {Box, Collapse, Table, TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {GET_TEAM_DETAILS} from "../../Teams/TeamsService";
import {GET_PROFILE_BY_ID} from "../../Membership/MembershipService";
import {GET_RESOLVE_USERS} from "../../../graphql/userGraphql";
import {RESOLVE_CLUBS} from "../SuperAdminService";
import {SnackBarContext} from "../../../context/SnackBarContext";
import useGraphql from "../../../Hooks/useGraphql";
import useLog from "../../../Hooks/useLog";

const ProfilesTableRow = ({ data = {}}) => {
  const [open, setOpen] = React.useState(false);

  return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {data?.firstName}
          </TableCell>
          <TableCell>{data?.lastName}</TableCell>
          <TableCell>{data?.team}</TableCell>
          <TableCell>{data?.club}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Invitations
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Reference To</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.invitations.map((invitation, index) => <InvitationsRow key={invitation.toid + index} invitation={invitation}/>)}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
  );
};


export const getDetailsFromInvitationType = (invitationType, id, setData, axiosGraphQL, errorLog, snackBar) => {
  switch(invitationType) {
    case "userForProfile":
    case "coachForTeam":
    case "parentForProfile":
      axiosGraphQL({
        query: GET_RESOLVE_USERS,
        variables: {ids: [id]},
      }).then(response => {
        const parent = response?.data?.data?.resolveUserIds[0]
        setData(`${parent?.firstName} ${parent?.lastName}`)
      }).catch((error) => {
        errorLog(error, "ProfilesTableRow", "getDetailsFromInvitationType", "GET_RESOLVE_USERS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
      break;
    case "teamToClub":
      axiosGraphQL({
        query: GET_TEAM_DETAILS,
        variables: { teamIds: [id]},
      }).then((response) => {
        const teamDetails = response.data.data.teamDetails;
        setData(teamDetails?.name)
      }).catch((error) => {
        errorLog(error, "ProfilesTableRow", "getDetailsFromInvitationType", "GET_TEAM_DETAILS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
      break;
  }
}

export const getDetailsToInvitationType = (type, id, setData, axiosGraphQL, errorLog, snackBar) => {
  switch(type) {
    case "team":
      axiosGraphQL({
        query: GET_TEAM_DETAILS,
        variables: { teamIds: [id]},
      }).then((response) => {
        const teamDetails = response.data.data.teamDetails;
        setData(teamDetails?.name)
      }).catch((error) => {
        errorLog(error, "ProfilesTableRow", "getDetailsToInvitationType", "GET_TEAM_DETAILS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
      break;
    case "user":
      axiosGraphQL({
        query: GET_RESOLVE_USERS,
        variables: {ids: [id]},
      }).then(response => {
        const parent = response?.data?.data?.resolveUserIds[0]
        setData(`${parent?.firstName} ${parent?.lastName}`)
      }).catch((error) => {
        errorLog(error, "ProfilesTableRow", "getDetailsToInvitationType", "GET_RESOLVE_USERS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
      break;
    case "club":
      axiosGraphQL({
        query: RESOLVE_CLUBS,
        variables: {ids: [id]},
      }).then(response => {
        const club = response?.data?.data?.ResolveClubs[0]
        setData(club.name)
      }).catch((error) => {
        errorLog(error, "ProfilesTableRow", "getDetailsToInvitationType", "RESOLVE_CLUB");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  }
}

const InvitationsRow = ({invitation}) => {
  const [fromDetails, setFromDetails] = useState('')
  const [toDetails, setToDetails] = useState('')
  const [itemDetails, setItemDetails] = useState('')
  const snackBar = useContext(SnackBarContext);
  const {axiosGraphQL} = useGraphql();
  const {errorLog} = useLog();

  useEffect(() => {
    getDetailsFromInvitationType(invitation.invitationType, invitation.fromId, setFromDetails, axiosGraphQL, errorLog, snackBar)
    getDetailsToInvitationType(invitation.invitationToType, invitation.toId, setToDetails, axiosGraphQL, errorLog, snackBar)
  if(invitation.context.itemType === 'profile') {
    axiosGraphQL({query: GET_PROFILE_BY_ID, variables: {ids: [invitation?.context?.itemId]}})
        .then((response) => {
          const profile = response.data.data.profilesById[0];
          setItemDetails(`${profile.firstName} ${profile.lastName}`)
        }).catch((error) => {
          errorLog(error, "InvitationsRow", "useEffect", "GET_PROFILE_BY_ID");
          snackBar.setOptions({
              alertMessage: error.message,
              alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
  } else if(invitation.context.itemType === 'team') {
      axiosGraphQL({
        query: GET_TEAM_DETAILS,
        variables: { teamIds: [invitation?.context?.itemId]},
      }).then((response) => {
            const teamDetails = response.data.data.teamDetails;
            setItemDetails(teamDetails?.name)
      }).catch((error) => {
          errorLog(error, "InvitationsRow", "useEffect", "GET_TEAM_DETAILS");
          snackBar.setOptions({
              alertMessage: error.message,
              alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
      });
    }
  }, [])

  return (
      <TableRow key={invitation?.fromId}>
        <TableCell component="th" scope="row">
          {fromDetails}
        </TableCell>
        <TableCell>{toDetails}</TableCell>
        <TableCell>{itemDetails}</TableCell>
        <TableCell>
          {invitation?.status}
        </TableCell>
        <TableCell >
          {invitation?.invitationType}
        </TableCell>
      </TableRow>
  )
}
ProfilesTableRow.propTypes = {};

export default ProfilesTableRow;
