import React from "react";
import Table from "../../../Components/Table/Table";
import TeamsTableRow from "./TeamsTableRow";
import { useTranslation } from "../../../context/Internationalization";

const schema = (t) => [
  {
    id: 'name',
    label: t('team_name'),
  },
  {
    id: 'club',
    label: t('club_page_title'),
  },
  {
    id: 'teamCode',
    label: t('team_code'),
  },
  {
    id: 'role',
    label: t('role'),
  },
]

const TeamsTable = ({ data, menuActions = [], lastElementRef }) => {
  const { t } = useTranslation();
  const rowElement = (dato, index) => <TeamsTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema(t)} rowElement={rowElement}/>);
};

export default TeamsTable;
