import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import UploadIcon from "../../assets/images/Svg/UploadIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "../../context/Internationalization";
import { fileIcons, getMimeType } from "../../utility/Utils";
import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";

const { Primary, Grey, Error } = colors;
const { text } = fonts;

const useStyles = makeStyles((_theme) => ({
  label: {
    ...text.sm.medium,
    color: Grey[700],
  },
  highlightText: {
    ...text.sm.normal,
    color: Primary[700],
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  errorText: {
    ...text.sm.normal,
    color: Error[500],
  },
  text: {
    ...text.sm.normal,
    color: Grey[500],
  },
  previewText: {
    ...text.sm.normal,
    color: Grey[500],
    textAlign: "center",
    display: "block",
  },
  uploadFileContainer: {
    border: `1px dashed ${Grey[300]}`,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cover: {
    width: "100%",
    height: "178px !important",
    objectFit: "cover",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  deleteButton: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: Error[500],
    "&:hover": {
      backgroundColor: Error[700],
    },
  },
  titleWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const MultipleFilesUpload = ({
  label,
  name,
  value,
  onChange,
  acceptedFileTypes,
  singleFileUpload,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [fileErrors, setFileErrors] = useState([]);

  const areImagesAllowed = Object.keys(acceptedFileTypes).some((type) =>
    type.startsWith("image/")
  );
  const areVideosAllowed = Object.keys(acceptedFileTypes).some((type) =>
    type.startsWith("video/")
  );
  const areDocumentsAllowed = Object.keys(acceptedFileTypes).some((type) =>
    type.startsWith("application/")
  );

  const getFileIcon = (fileType) => {
    let icon = null;
    fileIcons.map((fileIcon) => {
      if (fileIcon.type === fileType) icon = fileIcon.icon;
    });

    return icon;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newErrors = [];
      const newFiles = singleFileUpload
        ? []
        : Array.isArray(value)
        ? [...value]
        : [];

      acceptedFiles.forEach((selectedFile) => {
        if (!Object.keys(acceptedFileTypes).includes(selectedFile.type)) {
          newErrors.push(t("invalid_file_type"));
        }

        if (
          (areImagesAllowed || areVideosAllowed || areDocumentsAllowed) &&
          selectedFile.size > 15728640 // 15MB
        ) {
          newErrors.push(t("invalid_file_size"));
        }

        if (newErrors.length === 0) {
          if (singleFileUpload) {
            newFiles[0] = selectedFile;
          } else {
            newFiles.push(selectedFile);
          }
        }
      });

      setFileErrors(newErrors);

      if (newErrors.length === 0) {
        onChange({ target: { name, value: newFiles } });
      }
    },
    [
      onChange,
      name,
      value,
      acceptedFileTypes,
      areImagesAllowed,
      areVideosAllowed,
      areDocumentsAllowed,
      singleFileUpload,
      t,
    ]
  );

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: value && value.length > 0,
    onError: (err) => console.log("dropzone error", err),
  });

  const addFile = () => {
    inputRef.current.click();
  };

  const removeFile = (index, event) => {
    // Prevent the click event from propagating up to the parent div
    event.stopPropagation();

    const newFiles = Array.isArray(value) ? [...value] : [];
    newFiles.splice(index, 1);
    onChange({ target: { name, value: newFiles } });
  };

  const renderPreview = (file, index) => {
    const isImageType =
      file instanceof Blob || file instanceof MediaSource
        ? file?.type?.includes("image")
        : getMimeType(file.split(".").pop()).includes("image");
    const isVideoType =
      file instanceof Blob || file instanceof MediaSource
        ? file?.type?.includes("video")
        : getMimeType(file.split(".").pop()).includes("video");
    const fileName = file?.name || file.split("/").pop();

    return (
      <ImageListItem key={index}>
        {/* Preview */}
        {isImageType && (
          <img
            className={styles.cover}
            src={
              file instanceof Blob || file instanceof MediaSource
                ? URL.createObjectURL(file)
                : file
            }
            loading="lazy"
            alt={file?.name || "image"}
          />
        )}
        {isVideoType && (
          <video autoPlay loop muted className={styles.cover}>
            <source
              src={
                file instanceof Blob || file instanceof MediaSource
                  ? URL.createObjectURL(file)
                  : file
              }
            />
          </video>
        )}
        {!isImageType &&
          !isVideoType &&
          getFileIcon(
            file instanceof Blob || file instanceof MediaSource
              ? file?.type
              : getMimeType(file.split(".").pop())
          )}
        <ImageListItemBar
          title={<div className={styles.titleWrap}>{fileName}</div>}
          actionIcon={
            <>
              {/* Delete */}
              <IconButton
                aria-label={t("delete")}
                className={styles.deleteButton}
                color={"neutral"}
                onClick={(event) => removeFile(index, event)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          }
        />
      </ImageListItem>
    );
  };

  const renderFileErrors = (fileErrors) => {
    return (
      <span className={styles.errorText}>
        {fileErrors.map((error, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {error}
          </React.Fragment>
        ))}
      </span>
    );
  };

  const renderButton = () => {
    return (
      <Button
        {...getRootProps()}
        onClick={addFile}
        fullWidth
        startIcon={singleFileUpload ? <ThreeSixtyIcon /> : <AddIcon />}
        sx={{ mt: 2 }}
      >
        {singleFileUpload ? t("replace_file") : t("add_file")}
      </Button>
    );
  };

  const renderUploadArea = () => {
    return (
      <>
        <input {...getInputProps()} />
        <UploadIcon />
        {isDragActive ? (
          <p>
            {" "}
            <span className={styles.text}>{t("drop_files_here")}</span>
          </p>
        ) : (
          <>
            <div>
              <span className={styles.highlightText}>
                {t("click_to_upload")}
              </span>{" "}
              <span className={styles.text}>{t("drag_n_drop")}</span>
            </div>
            {fileErrors.length > 0 ? (
              renderFileErrors(fileErrors)
            ) : (
              <span className={styles.text}>
                {areImagesAllowed && <strong>{t("image_upload_files")}</strong>}
                {areVideosAllowed && (
                  <>
                    <br />
                    <strong>{t("video_upload_files")}</strong>
                  </>
                )}
                {areDocumentsAllowed && (
                  <>
                    <br />
                    <strong>{t("document_upload_files")}</strong>
                  </>
                )}
              </span>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {label && label?.length > 0 && (
        <span className={styles.label}>{label}</span>
      )}
      <div {...getRootProps()} className={styles.uploadFileContainer}>
        {Array.isArray(value) &&
        value.filter((item) => item !== "" && item !== null).length > 0 ? (
          <>
            {value.length === 1 ? (
              renderPreview(value[0], 0)
            ) : (
              <ImageList
                sx={{ width: 360, height: 360 }}
                cols={2}
                rowHeight={178}
              >
                {value.map((file, index) => renderPreview(file, index))}
              </ImageList>
            )}

            <input {...getInputProps()} style={{ display: "none" }} />
            {renderFileErrors(fileErrors)}
            {renderButton()}
          </>
        ) : (
          renderUploadArea()
        )}
      </div>
    </div>
  );
};

MultipleFilesUpload.propTypes = {};

export default MultipleFilesUpload;
