import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalActions } from "../../../Components/Modal";
import { Button } from "@mui/material";
import ModalTitle from "../../../Components/Modal/ModalTitle";
import ModalBody from "../../../Components/Modal/ModalBody";
import { useTranslation } from "../../../context/Internationalization";

const LastClubAdminInformationModal = ({ open: user, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={user} onClose={onClose} type={"info"}>
      <ModalTitle title={`${t("last_club_admin")}`} />
      <ModalBody>
        <span>
          {`${t("the_club_admin")} ${user?.firstName} ${user?.lastName} ${t(
            "club_admin_remove_message2"
          )} ${user?.mobile} ${t("last_club_admin_information_message")}`}
          <br />{" "}
        </span>
      </ModalBody>
      <ModalActions>
        <Button
          variant={"outlined"}
          color={"primary"}
          fullWidth
          onClick={onClose}
        >
          {t("ok")}
        </Button>
      </ModalActions>
    </Modal>
  );
};

LastClubAdminInformationModal.propTypes = {};

export default LastClubAdminInformationModal;
