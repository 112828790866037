import React from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

const SnackBar = ({ open, options, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={options.autoHideDuration}
      anchorOrigin={{
        vertical: options.vertical,
        horizontal: options.horizontal,
      }}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={options.alertSeverity}
        sx={{ width: "100%" }}
      >
        <AlertTitle>
          <strong>{options.title}</strong>
        </AlertTitle>
        {options.alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
