// ** React Imports
import { useState, createContext, useContext } from "react";

// ** Core Language Data
import messagesEn from "../assets/translations/en.json";
import messagesGr from "../assets/translations/gr.json";

// ** Menu msg obj
const translations = {
  en: messagesEn,
  gr: messagesGr,
};

// ** Create Context
const Context = createContext({});

const getLocalization = () => {
  const lang = localStorage.getItem("language");
  if (lang) {
    return {
      lang,
      translations: translations[lang] ? translations[lang] : translations.gr,
    };
  }
  return {
    lang: "gr",
    translations: translations.gr,
  };
};
const TranslationProvider = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState(getLocalization());

  // ** Switches Language
  const switchLanguage = (lang) => {
    if (!translations[lang]) return;
    localStorage.setItem("language", lang);
    setLocale((prev) => ({
      ...prev,
      lang,
      translations: translations[lang],
    }));
  };

  const t = (key) => locale.translations[key] || key;

  return (
    <Context.Provider value={{ lang: locale.lang, switchLanguage, t }}>
      {children}
    </Context.Provider>
  );
};

export const useTranslation = () => {
  return useContext(Context);
};

export default TranslationProvider;
