import React, { useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import GbFlag from "../assets/images/flags/GB.svg";
import GrFlag from "../assets/images/flags/GR.svg";
import { useTranslation } from "../context/Internationalization";
import { SnackBarContext } from "../context/SnackBarContext";
import useGraphql from "../Hooks/useGraphql";
import useLog from "../Hooks/useLog";
import { getStorage } from "../auth/utils";
import { EDIT_USER } from "../graphql/userGraphql";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  marginRight: 0,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      margin: 1,
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('${GrFlag}')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('${GbFlag}')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
const TranslationToggle = (props) => {
  const { switchLanguage, lang } = useTranslation();
  const snackBar = useContext(SnackBarContext);
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();
  const { t } = useTranslation();

  const updateUserLanguage = async (lang) => {
    try {
      await axiosGraphQL({ query: EDIT_USER, variables: { language: lang } });
    } catch (error) {
      errorLog(error, "TranslationToggle", "updateUserLanguage", "EDIT_USER");
      snackBar.setOptions({
        alertMessage: error.message,
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
    }
  };

  const onChangeLanguage = () => {
    const language = lang === "gr" ? "en" : "gr";
    switchLanguage(language);
    const isLoggedUser = getStorage().getItem("user") !== null;
    if (isLoggedUser) updateUserLanguage(language);
  };

  return (
    <FormGroup>
      <Tooltip
        title={lang === "gr" ? t("switch_to_english") : t("switch_to_greek")}
      >
        <FormControlLabel
          control={<MaterialUISwitch sx={{ m: 1 }} checked={lang === "gr"} />}
          onChange={onChangeLanguage}
        />
      </Tooltip>
    </FormGroup>
  );
};

TranslationToggle.propTypes = {};

export default TranslationToggle;
