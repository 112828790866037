import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    clubTeams: [],
  });

  const setAppInfo = ({ clubTeams }) => {
    setAppState({ clubTeams });
  };

  const reset = () => {
    setAppState({});
  };

  return (
    <Provider
      value={{
        appState: { clubTeams: appState.clubTeams },
        setClubTeams: (clubTeams) => setAppInfo({ ...appState, clubTeams }),
        reset,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAppState = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
