import React from 'react';
import PropTypes from 'prop-types';
import Stack from "@mui/material/Stack";
import useStyles from "./modalStyles";

const ModalBody = ({children}) => {
    const styles = useStyles()

    return (
        <Stack className={styles.modalBody} direction={'column'} justifyContent={'center'} alignItems={'center'} >
            {children}
        </Stack>
    );
};

ModalBody.propTypes = {

};

export default ModalBody;
