import React, {useEffect, useState, useContext} from "react";
import PropTypes from "prop-types";
import {TableCell, TableRow} from "@mui/material";
import {GET_PROFILE_BY_ID} from "../../Membership/MembershipService";
import {GET_TEAM_DETAILS} from "../../Teams/TeamsService";
import {getDetailsFromInvitationType, getDetailsToInvitationType} from "../ProfilesTable/ProfilesTableRow";
import {SnackBarContext} from "../../../context/SnackBarContext";
import useGraphql from "../../../Hooks/useGraphql";
import useLog from "../../../Hooks/useLog";

const UserInvitationsTableRow = ({ data = {}, menuActions, lastElementRef}) => {
    const [fromDetails, setFromDetails] = useState('')
    const [toDetails, setToDetails] = useState('')
    const [itemDetails, setItemDetails] = useState('')
    const snackBar = useContext(SnackBarContext);
    const {axiosGraphQL} = useGraphql();
    const {errorLog} = useLog();

    useEffect(() => {
        getDetailsFromInvitationType(data.invitationType, data.fromId, setFromDetails, axiosGraphQL, errorLog, snackBar)
        getDetailsToInvitationType(data.invitationToType, data.toId, setToDetails, axiosGraphQL, errorLog, snackBar)
        if(data.context.itemType === 'profile') {
            axiosGraphQL({query: GET_PROFILE_BY_ID, variables: {ids: [data?.context?.itemId]}})
                .then((response) => {
                    const profile = response.data.data.profilesById[0];
                    setItemDetails(`${profile.firstName} ${profile.lastName}`)
                }).catch((error) => {
                    errorLog(error, "UserInvitationsTableRow", "useEffect", "GET_PROFILE_BY_ID");
                    snackBar.setOptions({
                        alertMessage: error.message,
                        alertSeverity: "error",
                    });
                    snackBar.setSnackbarOpen(true);
                });
        } else if(data.context.itemType === 'team') {
            axiosGraphQL({
                query: GET_TEAM_DETAILS,
                variables: { teamIds: [data?.context?.itemId]},
            }).then((response) => {
                const teamDetails = response.data.data.teamDetails;
                setItemDetails(teamDetails?.name)
            }).catch((error) => {
                errorLog(error, "UserInvitationsTableRow", "useEffect", "GET_TEAM_DETAILS");
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
        }
    }, [])

  return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row">
                {fromDetails}
            </TableCell>
            <TableCell>{toDetails}</TableCell>
            <TableCell>{itemDetails}</TableCell>
            <TableCell>
                {data?.status}
            </TableCell>
            <TableCell >
                {data?.invitationType}
            </TableCell>
        </TableRow>
  );
};

UserInvitationsTableRow.propTypes = {};

export default UserInvitationsTableRow;
