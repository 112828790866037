import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import useStyles from "./formStyles";
import Stack from "@mui/material/Stack";
import {FORM_VARIANTS} from "../../utility/Enum";
import {colors} from "../../theme/colors";

const {Grey} = colors;

const groupByRow = (schema) => {
    const group = {}
    schema.map(field => {
        if (!field || !field.row) {
            return
        }

        if (group[field.row]) {
            group[field.row] = [...group[field.row], field]
        } else {
            group[field.row] = [field]
        }
    })

    return group
}

const groupBySection = (schema) => {
    const group = {}
    schema.map(field => {
        if (!field || !field.section || !field.section.title) {
            return
        }

        if (group[field.section.title]) {
            group[field.section.title] = [...group[field.section.title], field]
        } else {
            group[field.section.title] = [field]
        }
    })

    return group
}
const renderColumnComponents = ({ columnLength, onChange, values, styles, errors, maxLength = 12 }) => (column) => {
    // Ensure renderer is valid JSX
    const renderer = column.renderer ? (
        column.renderer({
            ...column,
            value: values[column.name],     // Pass value
            onChange,                       // Pass onChange handler
            error: errors[column.name],     // Pass error message
            styles,                         // Pass styles if necessary
            values                          // Pass other form values if needed
        })
    ) : null;

    // Only render if the renderer output is a valid React element
    return (
        <Grid key={column.name} sx={{ display: column.hidden ? 'none' : '' }} item xs={12} md={column.md || maxLength / columnLength}>
            {React.isValidElement(renderer) ? renderer : null}
        </Grid>
    );
};


const Form = ({onChange, values, schema = [], errors = {}, variant = FORM_VARIANTS.fields}) => {
    const styles = useStyles()

    let components
    if (variant === FORM_VARIANTS.sections) {
        const groupedBySection = groupBySection(schema)

        components = Object.entries(groupedBySection).map(([section, fields]) => {
            const groupedByRow = groupByRow(fields)
            const sectionDetails = fields[0].section
            return <Grid key={section} item xs={12} md={12}>
                <div style={{borderTop: `1px solid ${Grey[200]}`, margin: '16px 0'}}/>
                <Stack direction={'row'} spacing={1}>
                    <Grid item md={4}>
                        <Stack direction={'column'} >
                            <span className={styles.sectionTitle}>{sectionDetails.title}</span>
                            <span className={styles.sectionSubTitle}>{sectionDetails.subtitle}</span>
                        </Stack>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container spacing={1}>
                            {Object.entries(groupedByRow).map(([row, columns]) => {

                                return columns.map(renderColumnComponents({
                                    columnLength: columns.length,
                                    onChange,
                                    values,
                                    styles,
                                    errors,
                                    maxLength: 8
                                }))
                            })}
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>


        })
    } else {
        const group = groupByRow(schema)
        components = Object.entries(group).map(([row, columns]) => {
            return columns.map(renderColumnComponents({columnLength: columns.length, onChange, values, styles, errors}))
        })
    }


    return (
        <Grid container spacing={3}>
            {components}
        </Grid>
    );
};

Form.propTypes = {
};

export default Form;
