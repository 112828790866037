import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Checkbox,
  ListItemText,
} from "@mui/material";
import MainTitle from "../../Components/Common/MainTitle/MainTitle";
import Loader from "../../Components/Loader";
import boxStyles from "../../styles/boxing";
import useGraphql from "../../Hooks/useGraphql";
import {
  EDIT_ROLE_PERMISSIONS_SA,
  GET_ROLES_SA,
  GET_PERMISSIONS_SA,
} from "./SuperAdminService";
import { useTranslation } from "../../context/Internationalization";
import useLog from "../../Hooks/useLog";
import { SnackBarContext } from "../../context/SnackBarContext";

const SuperAdminRoles = () => {
  const { t } = useTranslation();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();
  const snackBar = useContext(SnackBarContext);

  const [roles, setRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [editingRole, setEditingRole] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchRoles();
    fetchAllPermissions();
  }, []);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await axiosGraphQL({ query: GET_ROLES_SA });
      setRoles(response.data.data.getRolesSA || []);
    } catch (error) {
      errorLog(error, "SuperAdminRoles", "fetchRoles", "GET_ROLES_SA");
      snackBar.setOptions({
        alertMessage: error.message,
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
    }
    setLoading(false);
  };

  const fetchAllPermissions = async () => {
    try {
      const response = await axiosGraphQL({ query: GET_PERMISSIONS_SA });
      setAllPermissions(response.data.data.getPermissionsSA || []);
    } catch (error) {
      errorLog(
        error,
        "SuperAdminRoles",
        "fetchAllPermissions",
        "GET_PERMISSIONS_SA"
      );
      snackBar.setOptions({
        alertMessage: error.message,
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
    }
  };

  const handleEdit = (role) => {
    setEditingRole(role);
    setSelectedPermissions(role.permissions);
  };

  const handleChange = (event) => {
    setSelectedPermissions(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axiosGraphQL({
        query: EDIT_ROLE_PERMISSIONS_SA,
        variables: { role: editingRole.role, permissions: selectedPermissions },
      });
      snackBar.setOptions({
        alertMessage: t("permissions_updated"),
        alertSeverity: "success",
      });
      snackBar.setSnackbarOpen(true);
      fetchRoles();
      setEditingRole(null);
    } catch (error) {
      errorLog(
        error,
        "SuperAdminRoles",
        "handleSave",
        "EDIT_ROLE_PERMISSIONS_SA"
      );
      snackBar.setOptions({
        alertMessage: error.message,
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
    }
    setLoading(false);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(255,255,255, 0.4)",
            zIndex: 100,
          }}
        >
          <Loader />
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={boxStyles.headerContainer} component="div">
            <Stack direction={"column"} spacing={1}>
              <MainTitle title={t("roles_permissions_admin")} />
            </Stack>
          </Box>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} style={boxStyles.filtersContainer}>
          <Grid container spacing={2}>
            {roles.map((role) => (
              <Grid item xs={12} md={6} key={role.role}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "16px",
                  }}
                >
                  {/* Role Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "8px",
                    }}
                  >
                    {t(role.role.replace("-", "_"))}
                  </Typography>

                  {editingRole?.role === role.role ? (
                    <Grid item sx={{ marginTop: "16px" }}>
                      <FormControl fullWidth sx={{ paddingTop: "8px" }}>
                        <InputLabel>{t("permissions")}</InputLabel>
                        <Select
                          multiple
                          value={selectedPermissions}
                          onChange={handleChange}
                          renderValue={(selected) =>
                            selected
                              .map((name) => {
                                const permission = allPermissions.find(
                                  (p) => p.name === name
                                );
                                return permission
                                  ? permission.description
                                  : name;
                              })
                              .join(", ")
                          }
                        >
                          {allPermissions.map((permission) => (
                            <MenuItem
                              key={permission.name}
                              value={permission.name}
                            >
                              <Checkbox
                                checked={selectedPermissions.includes(
                                  permission.name
                                )}
                              />
                              <ListItemText primary={permission.description} />
                            </MenuItem>
                          ))}
                        </Select>

                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                          <Button
                            onClick={() => setEditingRole(null)}
                            variant={"outlined"}
                            color={"primary"}
                            size="small"
                          >
                            {t("close")}
                          </Button>
                          <Button
                            onClick={handleSave}
                            disabled={loading}
                            type="button"
                            size="small"
                          >
                            {t("save")}
                          </Button>
                        </Stack>
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Box
                        sx={{
                          maxHeight: "200px",
                          height: "200px",
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          padding: "8px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "pre-line" }}
                        >
                          {allPermissions
                            .filter((permission) =>
                              role.permissions.includes(permission.name)
                            )
                            .map((permission) => permission.description)
                            .join("\n")}
                        </Typography>
                      </Box>

                      <Button
                        onClick={() => handleEdit(role)}
                        variant={"outlined"}
                        color={"primary"}
                        size="small"
                        sx={{ mt: 2 }}
                      >
                        {t("edit")}
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuperAdminRoles;
