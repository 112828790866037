import { DateTime } from "luxon";
import { matchIsValidTel } from "mui-tel-input";

export const isEmpty = (value = "") => value?.length === 0;
export const isEmptyArray = (value = []) => value?.length <= 0;
export const isEmptyObject = (value = {}) => !(Object.keys(value)?.length > 0);

export const isInvalidDate = (value = "") => {
  if (!isEmpty(value)) {
    const date = DateTime.fromISO(value);

    return !date.isValid;
  }

  return false;
};

export const isDateBeforeNow = (date = DateTime.now()) => {
  if (!isEmpty(date) && !isInvalidDate(date)) {
    return date.startOf("day") < DateTime.now().startOf("day");
  }

  return false;
};

export const isYearAfterCurrent = (date = DateTime.now()) => {
  if (!isEmpty(date) && !isInvalidDate(date)) {
    return date.year > DateTime.now().year;
  }
};

export const isTimeBeforeNow = (time = DateTime.now()) => {
  if (!isEmpty(time) && !isInvalidDate(time)) {
    return time < DateTime.now();
  }

  return false;
};

export const isNotZero = (value = 0) => value !== 0;
export const isNotZeroOrNegative = (value = 0) => value <= 0;
export const isNotNegative = (value = 0) => value < 0;
export const isNotGreaterThan = (value, limit) => value > limit;
export const isNotLessThan = (value, limit) => value < limit;
export const isNotDecimal = (value = 0) => value % 1 !== 0;

export const isInvalidEmail = (value = "") =>
  value.length > 0 &&
  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

export const isInvalidPhoneNumber = (value = "") => {
  return !matchIsValidTel(value, { onlyCountries: ["GR", "CY"] });
};

export const isDateTimeAfterDateTime = (timeToBeCompared, time) => {
  const timeToBeComparedDateTime = DateTime.fromISO(timeToBeCompared);
  const timeDateTime = DateTime.fromISO(time);

  return timeToBeComparedDateTime > timeDateTime;
};

export const isDateTimeBeforeDateTime = (timeToBeCompared, time) => {
  const timeToBeComparedDateTime = DateTime.fromISO(timeToBeCompared);
  const timeDateTime = DateTime.fromISO(time);

  return timeToBeComparedDateTime < timeDateTime;
};

export const isInvalidTime = (value = "") => {
  return isInvalidDate(value);
};

export const formValidator = ({ schema, values }) => {
  const errors = {};
  schema
    .filter((field) => !field.hidden)
    .forEach((field) => {
      if (field.validators) {
        field.validators.forEach(
          ({ validator, text, dependency, specificValue }) => {
            if (dependency) {
              if (validator(values[field.name], values[dependency])) {
                errors[field.name] = text(field.label);
              }
            } else if (specificValue) {
              if (validator(values[field.name], specificValue)) {
                errors[field.name] = text(field.label);
              }
            } else {
              if (validator(values[field.name])) {
                errors[field.name] = text(field.label);
              }
            }
          }
        );
      }
    });

  return errors;
};
