import React from "react";
import Table from "../../../Components/Table/Table";
import ProfilesTableRow from "./ProfilesTableRow";
import { useTranslation } from "../../../context/Internationalization";

const schema = (t) => [
  {
    id: '',
    label: ''
  },
  {
    id: 'firstName',
    label: t('firstName'),
  },
  {
    id: 'lastName',
    label: t('lastName'),
  },
  {
    id: 'team',
    label: t('team'),
  },
  {
    id: 'club',
    label: t('create_club')
  }
]

const ProfilesTable = ({ data, menuActions = [], lastElementRef }) => {
  const { t } = useTranslation();
  const rowElement = (dato, index) => <ProfilesTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema(t)} rowElement={rowElement}/>);
};

export default ProfilesTable;
