export const FORM_DESCRIPTOR = `
  fragment formFields on FormDesciptor {
    _id
    formGroups {
        label
        formFields {
            name
            type
            label
            placeholder
            required
            validations {
                type
                errorLabel
                value
            }
            optionSettings
            options {
                label
                value
            }
        }
    }
    formDescription
    formName
    owner
    club
    dueDate
    submitter
    createdAt
    updatedAt
  }
`;

export const FORM_ASSIGNMENT_FIELDS = `
fragment formAssignmentFields on FormAssignment {
 _id
 formId
 dueDate
 submitterId
 submitterType
 visibleOnProfile
 teamId
 profileId
 submitionStatus
 forProfileId
 updatedAt
 answeredFormGroups {
  label
  formFields {
    name
    type
    label
    placeholder
    required
    validations {
      type
      errorLabel
      value
    }
    optionSettings
    options {
      label
      value
    }
    answer
  }
 }
}
`
export const CREATE_FORM_DESCRIPTOR = `
 ${FORM_DESCRIPTOR}
   mutation CreateFormDescriptor($formGroups: [FormGroupInput], $formName: String, $formDescription: String, $owner: String, $club: String, $status: String, $dueDate: String, $submitter: String) {
    createFormDescriptor(formGroups: $formGroups, formName: $formName, formDescription: $formDescription, owner: $owner, club: $club, status: $status, dueDate: $dueDate, submitter: $submitter) {
      ...formFields
    }
  }`

export const GET_FORM_DESCRIPTOR = `
${FORM_DESCRIPTOR}
    query GetFromDescriptors($club: String) {
        getFormDescriptors(club: $club) {
         ...formFields
        }
    }
`

export const GET_FORM = `
${FORM_DESCRIPTOR}
    query GetFromDescriptor($formId: String) {
        getFormDescriptor(formId: $formId) {
         ...formFields
        }
    }
`

export const ASSIGN_FORM_DESCRIPTOR = `
 ${FORM_ASSIGNMENT_FIELDS}
   mutation AssignForm( $formId: String, $visibleOnProfile: Boolean, $teamId: String, $profileId: String, $forProfileId: String) {
    assignForm(formId: $formId, visibleOnProfile: $visibleOnProfile, teamId: $teamId, profileId: $profileId, forProfileId: $forProfileId) {
      ...formAssignmentFields
    }
  }`

export const GET_FORM_ASSIGNMENTS = `
${FORM_ASSIGNMENT_FIELDS}
    query GetFormAssignments($formId: String, $skip: Int, $limit: Int, $sortingDir: String, $sortingField: String) {
        getFormAssignments(formId: $formId, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
         ...formAssignmentFields
        }
    }
`

export const REMOVE_FORM_ASSIGNMENT = `
 ${FORM_ASSIGNMENT_FIELDS}
   mutation RemoveFormAssignment( $formAssignmentId: String,) {
    removeFormAssignment(formAssignmentId: $formAssignmentId) {
      ...formAssignmentFields
    }
  }`

export const REJECT_ASSIGNMENT_ANSWER = `
 ${FORM_ASSIGNMENT_FIELDS}
   mutation RejectForm( $formAssignmentId: String, $rejectionReason: String, $backToSubmitter: Boolean) {
    rejectForm(formAssignmentId: $formAssignmentId, rejectionReason: $rejectionReason, returnToSubmitter: $backToSubmitter) {
      ...formAssignmentFields
    }
  }`

export const APPROVE_ASSIGNMENT_ANSWER = `
 ${FORM_ASSIGNMENT_FIELDS}
   mutation ApproveForm( $formAssignmentId: String) {
    approveForm(formAssignmentId: $formAssignmentId) {
      ...formAssignmentFields
    }
  }`

export const DELETE_FORM_DESCRIPTOR = `
   mutation DeleteFormDescriptor($formDescriptorId: String) {
    deleteFormDescriptor(formDescriptorId: $formDescriptorId) 
  }`

export const GET_PROFILE_ASSIGNMENTS = `
${FORM_ASSIGNMENT_FIELDS}
	query GetProfileAssignments($profileId: String) {
		GetProfileAssignments(profileId: $profileId) {
		 ...formAssignmentFields
		}
	}
`