import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from "../ToggleButton/ToggleButton";
import {Stack} from "@mui/material";
import useStyles from "./formStyles";

const ToggleRenderer = ({label, value, onChange, name, disabled, labelPosition = 'left', styles={}}) => {
    return (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'} sx={{height: '100%'}}>
            {labelPosition === 'left' && <span className={styles.label}>{label}</span>}
            <ToggleButton onChange={onChange} value={value} name={name} disabled={disabled}/>
            {labelPosition === 'right' && <span className={styles.label}>{label}</span>}
        </Stack>
    );
};

ToggleRenderer.propTypes = {
};

export default ToggleRenderer;
