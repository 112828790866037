import React from 'react';
import PropTypes from 'prop-types';
import Stack from "@mui/material/Stack";
import useStyles from "./modalStyles";

const ModalActions = ({children}) => {
    const styles = useStyles()

    return (
        <Stack className={styles.modalActions} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
            {children}
        </Stack>
    );
};

ModalActions.propTypes = {

};

export default ModalActions;
