import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@mui/material/SvgIcon";

const DeleteModalIcon = ({ sx = { color: "#FFE2E5", backgroundColor: '#FFE2E5', width: '48px', height: '48px', border: '8px solid #FFF1F2', borderRadius: '50%', }, ...props }) => {
    return (
        <SvgIcon {...props} sx={sx} width="57" height="56" viewBox="0 0 57 56" >
            <path d="M32.5 22V21.2C32.5 20.0799 32.5 19.5198 32.282 19.092C32.0903 18.7157 31.7843 18.4097 31.408 18.218C30.9802 18 30.4201 18 29.3 18H27.7C26.5799 18 26.0198 18 25.592 18.218C25.2157 18.4097 24.9097 18.7157 24.718 19.092C24.5 19.5198 24.5 20.0799 24.5 21.2V22M26.5 27.5V32.5M30.5 27.5V32.5M19.5 22H37.5M35.5 22V33.2C35.5 34.8802 35.5 35.7202 35.173 36.362C34.8854 36.9265 34.4265 37.3854 33.862 37.673C33.2202 38 32.3802 38 30.7 38H26.3C24.6198 38 23.7798 38 23.138 37.673C22.5735 37.3854 22.1146 36.9265 21.827 36.362C21.5 35.7202 21.5 34.8802 21.5 33.2V22" stroke="#FF4D5D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
};

DeleteModalIcon.propTypes = {
};

export default DeleteModalIcon;
