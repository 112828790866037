import React, { createContext, useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { colors } from "../theme/colors";
import { getStorage } from "../auth/utils";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { useTranslation } from "./Internationalization";
const { Warning, Base } = colors;

const SuperAdminContext = createContext();
const { Provider } = SuperAdminContext;

const useStyles = {
  impersonateBanner: {
    backgroundColor: Warning[700],
    color: Base.White,
    zIndex: 1300,
    position: "sticky",
    top: 0,
  },
  impersonateLeaveButton: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const SuperAdminProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const { setImpersonate } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const impersonate = getStorage().getItem("impersonate");
    if (impersonate) {
      setUser(JSON.parse(impersonate));
    }
  }, []);

  const handleSetUser = (user) => {
    getStorage().setItem("impersonate", JSON.stringify(user));

    setUser(user);
  };

  const handleLeaveImpersonate = () => {
    setUser(null);
    setTimeout(() => {
      getStorage().removeItem("impersonate");
      setImpersonate(null);
      navigate("/");
    }, 200);
  };

  return (
    <Provider value={{ user, setUser: handleSetUser }}>
      <Stack direction={"column"}>
        {user && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            style={useStyles.impersonateBanner}
          >
            {user?.firstName} {user?.lastName}
            <div
              style={useStyles.impersonateLeaveButton}
              onClick={handleLeaveImpersonate}
            >
              {t("leave_impersonate")}
            </div>
          </Stack>
        )}
        {children}
      </Stack>
    </Provider>
  );
};

export const useSuperAdmin = () => {
  return useContext(SuperAdminContext);
};

export default SuperAdminProvider;
