import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalActions } from "./Modal";
import { Button } from "@mui/material";
import ModalTitle from "./Modal/ModalTitle";
import ModalBody from "./Modal/ModalBody";
import { useTranslation } from "../context/Internationalization";

const ClubChooseConfirmationModal = ({ open, onClose, onSave, clubName }) => {
  const { t } = useTranslation();

  const handleSave = () => {
    onSave();
  };

  return (
    <Modal open={open} onClose={onClose} type={"info"}>
      <ModalTitle title={t("choose_club_modal_title")} />
      <ModalBody>
        <span>
          {`${t("choose_club_confirmation_msg1")} ${clubName?.trim("")}. ${t(
            "choose_club_confirmation_msg2"
          )}`}
        </span>
      </ModalBody>
      <ModalActions>
        <Button
          variant={"outlined"}
          color={"primary"}
          fullWidth
          onClick={onClose}
        >
          {t("close")}
        </Button>
        <Button fullWidth onClick={handleSave}>
          {t("save")}
        </Button>
      </ModalActions>
    </Modal>
  );
};

ClubChooseConfirmationModal.propTypes = {};

export default ClubChooseConfirmationModal;
