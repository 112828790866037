import React, { lazy, useContext } from "react";
import { BrowserRouter as Router, Navigate, useRoutes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch"; // or any other pipeline

import AppShell from "./AppShell";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import TranslationProvider from "./context/Internationalization";
import { SnackBarProvider } from "./context/SnackBarContext";
import Club from "./pages/Club/Club";
import Login from "./pages/Login/Login";
import MessagesProvider from "./context/MessagesContext";
import SuperAdminClubs from "./pages/SuperAdmin/SuperAdminClubs";
import { isClubAdmin, isSuperAdmin } from "./utility/Utils";
import InvitationProvider from "./context/InvitationContext";
import SuperAdminProvider from "./context/SuperAdminContext";
import SuperAdminImpersonate from "./pages/SuperAdmin/SuperAdminImpersonate";
import OfficialLeagues from "./pages/SuperAdmin/OfficialLeagues/OfficialLeagues";
import { AppProvider } from "./context/AppContext";
import SuperAdminRoles from "./pages/SuperAdmin/SuperAdminRoles";

const Home = lazy(() => import("./pages/Home/Home"));
const Messages = lazy(() => import("./pages/Messages/Messages"));
const Files = lazy(() => import("./pages/Files/Files"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Forms = lazy(() => import("./pages/Forms/Forms"));

function App() {
  const auth = useContext(AuthContext);

  const user = auth.authState.user;

  const isUserSuperAdmin = isSuperAdmin(user);
  const isUserClubAdmin = isClubAdmin(user);
  let routes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <Navigate to="/news" />
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Home />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/club/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Club />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/files/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Files />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/messages/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Messages />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/shop/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Shop />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/forms/*",
      element:
        auth.isAuthenticated() && (isUserClubAdmin || isUserSuperAdmin) ? (
          <AppShell>
            <Forms />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/super-admin/clubs",
      element:
        auth.isAuthenticated() && isUserSuperAdmin ? (
          <AppShell>
            <SuperAdminClubs />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/super-admin/official-leagues/*",
      element:
        auth.isAuthenticated() && isUserSuperAdmin ? (
          <AppShell>
            <OfficialLeagues />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/super-admin/impersonate",
      element:
        auth.isAuthenticated() && isUserSuperAdmin ? (
          <AppShell>
            <SuperAdminImpersonate />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "/super-admin/roles",
      element:
        auth.isAuthenticated() && isUserSuperAdmin ? (
          <AppShell>
            <SuperAdminRoles />
          </AppShell>
        ) : (
          <Navigate to="/login" />
        ),
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ];
  if (!isUserSuperAdmin) {
    routes = routes
      .filter((route) => route.path !== "/super-admin/clubs")
      .filter((route) => route.path !== "/super-admin/impersonate")
      .filter((route) => route.path !== "/super-admin/roles")
      .filter((route) => route.path !== "/super-admin/official-leagues/*");
  }

  return (
    <SuperAdminProvider isUserSuperAdmin={isUserSuperAdmin}>
      {useRoutes(routes)}
    </SuperAdminProvider>
  );
}

const AppWrapper = () => {
  return (
    <div>
      <Router>
        {/*<ScrollToTop />*/}
        <div className="Container">
          <AuthProvider>
            <AppProvider>
              <TranslationProvider>
                <MessagesProvider>
                  <SnackBarProvider>
                    <InvitationProvider>
                      <DndProvider
                        backend={MultiBackend}
                        options={HTML5toTouch}
                      >
                        <App />
                      </DndProvider>
                    </InvitationProvider>
                  </SnackBarProvider>
                </MessagesProvider>
              </TranslationProvider>
            </AppProvider>
          </AuthProvider>
        </div>
      </Router>
    </div>
  );
};

export default AppWrapper;
