import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";
import { USER_ROLES } from "../../../utility/Enum";

const TeamsTableRow = ({ data = {} }) => {
  const isTeamAdmin = data?.teamAdminUsers.find(
    (admin) => admin === data.userId
  );

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell>{data.club?.name}</TableCell>
      <TableCell>{data.teamCode}</TableCell>
      <TableCell>
        {isTeamAdmin ? USER_ROLES.TEAM_ADMIN : USER_ROLES.COACH}
      </TableCell>
    </TableRow>
  );
};

TeamsTableRow.propTypes = {};

export default TeamsTableRow;
