import {styled} from "@mui/material/styles";
import {Switch} from "@mui/material";
import {colors} from "../../theme/colors";
import React from "react";
const {Primary, Grey} = colors;

const StyledToggleButton = styled(Switch)(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(15px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(15px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: Primary[600],
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: 'width 0.2s'
    },
    '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: Grey[200],
        boxSizing: 'border-box',
    },
}));

const ToggleButton = ({value = false, onChange, name, disabled}) => {
    const handleOnChange = (e, value) => {
        onChange && onChange({target: {name, value}})
    }
    return <StyledToggleButton onChange={handleOnChange} checked={value} disabled={disabled}/>
}
export default ToggleButton
