import React from "react";
import Table from "../../../Components/Table/Table";
import UserClubTableRow from "./UserClubTableRow";
import { useTranslation } from "../../../context/Internationalization";

const schema = (t) => [
  {
    id: 'name',
    label: t('team_name'),
  },
  {
    id: 'isPremium',
    label: t('isPremium'),
  },
  {
    id: 'isFC',
    label: t('isFC'),
  }
]

const UserClubTable = ({ data, menuActions = [], lastElementRef }) => {
  const { t } = useTranslation();
  const rowElement = (dato, index) => <UserClubTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema(t)} rowElement={rowElement}/>);
};

export default UserClubTable;
