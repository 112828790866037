import React, { createContext, useState } from "react";
import SnackBar from "../Components/Common/SnackBar/SnackBar";
import { useTranslation } from "./Internationalization";

const SnackBarContext = createContext();
const { Provider } = SnackBarContext;

const SnackBarProvider = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    autoHideDuration: 6000,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertSeverity: "success",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const setSnackBarTitle = (alertSeverity) => {
    let title = "";
    switch (alertSeverity) {
      case "error":
        title = t("error");
        break;
      case "success":
        title = t("success");
        break;
      case "info":
        title = t("info");
        break;
      case "warning":
        title = t("warning");
        break;
      default:
        title = t("success");
    }

    return title;
  };

  const setSnackBarAndAlertOptions = (snackBarOptions) => {
    setOptions({
      ...options,
      title: setSnackBarTitle(snackBarOptions.alertSeverity),
      ...snackBarOptions,
    });
  };

  const handleOpenSnackbar = (open) => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
    setOpen(open);
  };

  return (
    <Provider
      value={{
        setSnackbarOpen: handleOpenSnackbar,
        handleCloseSnackbar: (event, reason) =>
          handleCloseSnackbar(event, reason),
        setOptions: (snackBarOptions) =>
          setSnackBarAndAlertOptions(snackBarOptions),
      }}
    >
      {children}
      <SnackBar open={open} options={options} onClose={handleCloseSnackbar} />
    </Provider>
  );
};

export { SnackBarContext, SnackBarProvider };
