import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, Button, Grid} from "@mui/material";
import DeleteConfirmationModal from "../Posts/Components/DeleteConfirmationModal";
import boxStyles from "../../styles/boxing";
import Stack from "@mui/material/Stack";
import MainTitle from "../../Components/Common/MainTitle/MainTitle";
import Divider from "@mui/material/Divider";

import ClubsTable from "./ClubsTable/ClubsTable";
import {useTranslation} from "../../context/Internationalization";
import {AuthContext} from "../../context/AuthContext";
import {SnackBarContext} from "../../context/SnackBarContext";
import useInfinityScroll from "../../Hooks/useInfinityScroll";
import {DELETE_POST} from "../../graphql/postGraphql";
import {GET_CLUBS_SA} from "./SuperAdminService";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ClubsDrawer from "./ClubsTable/ClubsDrawer";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import RegisterUserDrawer from './RegisterUserDrawer';
import AddUserToClubDrawer from "./AddUserToClubDrawer";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const initialState = {
    clubs: [],
    teams: [],
    userIds: [],
    selectedPost: null,
    drawer: false,
    registerUserDrawer: false,
    deleteModal: false,
    action: "",
    postType: "all",
    loading: false,
    hasMore: true,
    skip: 0,
    limit: 20,
};

const tableMenuActions = (handleAction, handleAddUserToClub, t) => {
    return [
        {
            label: t('edit_club'),
            icon: <EditIcon fontSize="small" />,
            onClickAction: (club) => handleAction("edit", club),
        },
        {
            label: t('add_club_admin'),
            icon: <AddIcon fontSize="small" />,
            onClickAction: (club) => handleAddUserToClub(club),
        },
    ];
};

const SuperAdminClubs = props => {
    const { t } = useTranslation();
    const [state, setState] = useState(initialState);
    const [filters, setFilters] = useState({term: ''});
    const { authState } = useContext(AuthContext);
    const club = authState.user.club
    const clubId = club?._id;
    const snackBar = useContext(SnackBarContext);
    const {axiosGraphQL} = useGraphql()
    const {errorLog} = useLog();

    useEffect(() => {
        getClubs();
    }, [clubId, filters.term]);

    const updatePage = useCallback(async () => {
        getClubs(state.skip + 1);
    }, [state.skip, filters.term]);

    const lastElementRef = useInfinityScroll({
        updatePage,
        isLoading: state.loading,
        hasMore: state.hasMore,
    });

    
    const handleOpenDrawer = () => {
        setState({ ...state, selectedPost:  null,  drawer: true });
    };

    const handleOpenRegisterDrawer = () => {
        setState({ ...state, registerUserDrawer: true });
    };

    const handleCloseRegisterDrawer = () => {
        setState({ ...state, registerUserDrawer: false});
    };

    const handleCloseDrawer = () => {
        setState({ ...state, drawer: false, selectedClub: null });
    };

    const handleAction = (action, club) => {
        setState({ ...state, drawer: true, selectedClub: club });
    };

    const getClubs = (skip = 0) => {
        setState((previousState) => ({ ...previousState, loading: true }));

            axiosGraphQL({
                query: GET_CLUBS_SA,
                variables: {
                    term: filters.term,
                    skip: skip * 20,
                    limit: 20,
                },
            }).then((response) => {

                const { getClubsSA } = response.data.data;

                setState((previousState) => ({
                    ...previousState,
                    skip: skip,
                    hasMore:
                        skip === 0 && !(getClubsSA?.length < 20)
                            ? true
                            : !(getClubsSA?.length < 20),
                    loading: false,
                    drawer: false,
                    clubs:
                        skip === 0
                            ? getClubsSA
                            : [...previousState.clubs, ...getClubsSA],
                }));
            }).catch((error) => {
                errorLog(error, "SuperAdminClubs", "getClubs", "GET_CLUBS_SA");
                setState((previousState) => ({ ...previousState, loading: false }));
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
    };

    const onChangeFilter = ({target: {name, value}}) => {
        setFilters(prevState => ({...prevState, [name]: value}))
    }

    const onCloseDeleteModal = () => {
        setState(prevState => ({...prevState, deleteModal: false}))
    }

    const handleDeleteConfirmation = () => {
        axiosGraphQL({ query: DELETE_POST, variables: { id: state.selectedPost?._id } })
            .then(async () => {
                snackBar.setOptions({
                    alertMessage: t('post_deleted_successfully'),
                    alertSeverity: "success",
                });
                snackBar.setSnackbarOpen(true);
                await getClubs();
                onCloseDeleteModal();
            }).catch((error) => {
                errorLog(error, "SuperAdminClubs", "handleDeleteConfirmation", "DELETE_POST");
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
    };

    const handleAddUserToClub = (club) => {
        setState(prevState => ({...prevState, addUserDrawer: true, selectedClub:club }))
    }
    const closeAddUserToClub = () => {
        setState(prevState => ({...prevState, addUserDrawer: false, selectedClub: null }))
    }

    return ( <Box
            component="main"
            sx={{flexGrow: 1, p: 3}}>
            <Box>
                <DeleteConfirmationModal open={state.deleteModal} onClose={onCloseDeleteModal}
                                         onDelete={handleDeleteConfirmation}/>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={boxStyles.headerContainer} component="div">
                            <Stack direction={'row'} spacing={2}>
                                <MainTitle title={t('clubs_admin_panel')}/>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <Button type="button" size="small" onClick={handleOpenDrawer}>
                                    {t('new_club')}
                                </Button>
                                <Button type="button" size="small" onClick={handleOpenRegisterDrawer}>
                                    {t('register_user')}
                                </Button>
                            </Stack>
                        </Box>
                        <Divider style={{width: '100%'}}/>

                    </Grid>
                    <Grid item xs={12} style={boxStyles.filtersContainer}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                                <TextFieldRenderer label={t('search')} onChange={onChangeFilter} name={'term'} value={filters.term}/>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {state.clubs.length === 0 && !state.loading ? (
                            <span>{t("no_clubs_found")}</span>
                        ) : (
                            <ClubsTable
                                lastElementRef={lastElementRef}
                                clubs={state.clubs}
                                menuActions={tableMenuActions(
                                handleAction,
                                handleAddUserToClub,
                                t
                                )}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
            <ClubsDrawer
                openDrawer={state.drawer}
                onCloseDrawer={handleCloseDrawer}
                selectedClub={state.selectedClub}
                getClubs={getClubs}
            />
            <AddUserToClubDrawer
                openDrawer={state.addUserDrawer}
                onCloseDrawer={closeAddUserToClub}
                selectedClub={state.selectedClub}
                getClubs={getClubs}
            />
            <RegisterUserDrawer
                openDrawer={state.registerUserDrawer}
                onCloseDrawer={handleCloseRegisterDrawer}
            />
        </Box>
    );
};

SuperAdminClubs.propTypes = {};

export default SuperAdminClubs;
