export const colors = {
  Primary: {
    25: "#F5FDFD",
    50: "#EBFBFB",
    100: "#D6F7F7",
    200: "#ADEFEF",
    300: "#70E2E2",
    400: "#33D6D6",
    500: "#1AD1D1",
    600: "#00CCCC",
    700: "#00B2B2",
    800: "#067B7E",
    900: "#095053",
  },
  Error: {
    25: "#FFF8F9",
    50: "#FFF1F2",
    100: "#FFE2E5",
    200: "#FFC6CB",
    300: "#FF9BA4",
    400: "#FF5F6D",
    500: "#FF5F6D",
    600: "#FF4D5D",
    700: "#953440",
    800: "#6E2B35",
    900: "#5A272F",
  },
  Warning: {
    25: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    600: "#DC6803",
    700: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
  },
  Success: {
    25: "#F0FBF8",
    50: "#E2F8F0",
    100: "#D3F4E9",
    200: "#B6EDDB",
    300: "#8AE2C5",
    400: "#50D3A8",
    500: "#42D0A0",
    600: "#33CC99",
    700: "#2FBD8E",
    800: "#28A078",
    900: "#1D7557",
  },
  Grey: {
    25: "#FCFDFD",
    50: "#FAFAFC",
    100: "#F5F6F8",
    200: "#EAEDF1",
    300: "#CBD1DC",
    400: "#98A3B9",
    500: "#7E8CA7",
    600: "#4C5869",
    700: "#3A4552",
    800: "#1F2831",
    900: "#161F25",
  },
  Base: {
    White: "#FFF",
    Black: "#0D151A",
    PlayerDex: "#00E6E6",
    OffWhite: "#F5F7FA"
  },
};
