import {makeStyles} from "@mui/styles";
import {colors} from "../../theme/colors";
import {fonts} from "../../theme/fonts";
const {Grey, Base} = colors;
const {text} = fonts;

const useStyles = makeStyles((theme) => ({
    label: {
        ...text.sm.medium,
        color: Grey[700]
    },
    input: {
        border: `1px solid ${Grey[300]}`,
        borderRadius: '8px',
        paddingBottom: '22px'
    },
    helperText: {
        marginTop: '-4px'
    },
    helperLocationText: {
        marginTop: '-19px',
    },
    sectionTitle: {
        ...text.sm.medium,
        color: Grey[700]
    },
    sectionSubTitle: {
        ...text.sm.normal,
        color: Grey[500]

    },
    noDecoration: {
        border: 'none',
    },
    noPadding: {
        border: `1px solid ${Grey[300]}`,
        borderRadius: '8px',
        paddingBottom: '22px',
        paddingRight: '0px'
    },
    toggleComponentContainer: {
        backgroundColor: Grey[50],
        padding: '4px',
        borderRadius: '8px',
        border: `1px solid ${Grey[100]}`

    },
    activeToggleButton: {
        backgroundColor: Base.White,
        ...text.sm.medium,
        width: '100%',
        color: Grey[700],
        boxShadow: '0px 1px 3px rgba(22, 31, 37, 0.1), 0px 1px 2px rgba(22, 31, 37, 0.06)',
        border: 'none',
        '&:hover': {
            backgroundColor: Grey[200],
        },
        '&:focus': {
            backgroundColor: Grey[200],
        }
    },
    toggleButton: {
        backgroundColor: 'transparent',
        ...text.sm.medium,
        color: Grey[500],
        width: '100%',
        border: 'none',
        '&:hover': {
            backgroundColor: Grey[200],
        },
        '&:focus': {
            backgroundColor: Grey[200],
        }
    },
    colorDiv: {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        border: "1px solid #212D38",
        marginRight: "10px",
        display: "inline-block",
        verticalAlign: "middle",
    },
}))

export default useStyles
