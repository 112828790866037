// GRAPHQL Queries
import { CLUB_FIELDS } from "./clubGraphql";

export const USER_FIELDS = `
  fragment userFields on User {
    _id
    mobile
    email
    firstName
    lastName
    token
    firebaseToken
    dateOfBirth
    language
    roles
    photo
    contactInfo {
      phones
      emails
    }
    address {
      street
      state
      Country
    }
  }
`;

export const PERSON_RESOLVED_FIELDS = `
  fragment personResolvedFields on PersonResolved {
    _id
    firstName
    lastName
    mobile
    photo
  }
`;

export const TEAM_FIELDS_WITH_STAFF_AND_PERMISSIONS = `
  ${PERSON_RESOLVED_FIELDS}
  fragment teamFieldsWithStaffAndPermissions on TeamWithStaffWithPermissions {
    _id
    name
	  logo
    photo
    ageGroup
    season
    sport
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isFC
    isLocked
    permissions
    coachesInfo {
      ...personResolvedFields
    },
    teamAdminsInfo {
      ...personResolvedFields
    }
  }
`;

export const RESOLVE_USER_IDS_FIELDS = `
  fragment resolveUserIdsFields on ResolveUserIds {
    _id
     firstName
    lastName
    mobile
    photo
    lastSeen
    contactInfo {
      phones
      email
    }
  }
`;

export const GET_USER = `
  ${USER_FIELDS}
  ${CLUB_FIELDS}
  ${TEAM_FIELDS_WITH_STAFF_AND_PERMISSIONS}
  query GetUser {
    me {
      user {
        ...userFields
      }
      club {
        ...clubFields
      }
      clubs {
        ...clubFields
      }
      teams {
        ...teamFieldsWithStaffAndPermissions
      }
    }
  }
`;

export const GET_RESOLVE_USERS = `
  ${RESOLVE_USER_IDS_FIELDS}
  query getResolveUsersByIds($ids: [String]) {
    resolveUserIds(ids: $ids) {
      ...resolveUserIdsFields
    }
  }
`;

export const SEARCH_USER_WITH_MOBILE = `
 ${USER_FIELDS}
  query SearchUserWithMobile($mobile: String!) {
    searchUserWithMobile(mobile: $mobile) { 
      ...userFields
    }
  } 
`;

export const EDIT_USER = `
  mutation editUser($firstName: String, $lastName: String, $dateOfBirth: String, $mobile: String, $email: String, $language: String) {
    editUser(firstName: $firstName, lastName: $lastName, dateOfBirth: $dateOfBirth, mobile: $mobile, email: $email, language: $language) {
      _id
    }
  }
`;
