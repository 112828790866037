import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStorage } from "../auth/utils";
import { USER_ROLES } from "../utility/Enum";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const token = getStorage().getItem("token");
  const user = getStorage().getItem("user");
  const impersonate = getStorage().getItem("impersonate");

  const [authState, setAuthState] = useState({
    token,
    user: user ? JSON.parse(user) : {},
    impersonate: impersonate ? JSON.parse(impersonate) : {},
  });

  const setAuthInfo = ({ token, user }) => {
    getStorage().setItem("token", token);

    if (impersonate) {
      getStorage().setItem("impersonate", JSON.stringify(user));
    } else {
      getStorage().setItem("user", JSON.stringify(user));
      setAuthState({
        token,
        user,
      });
    }
  };

  const logout = () => {
    getStorage().removeItem("token");
    getStorage().removeItem("user");
    setAuthState({});
    navigate("/login");
  };

  const isAuthenticated = () => {
    return authState.token;
  };

  const isSuperAdmin = () => {
    return authState.user.roles.includes(USER_ROLES.SUPER_ADMIN);
  };

  const setImpersonate = (user) => {
    setAuthState((prevState) => ({ ...prevState, impersonate: user }));
  };

  const _user = impersonate
    ? authState.impersonate
      ? authState.impersonate
      : JSON.parse(impersonate)
    : authState.user;

  return (
    <Provider
      value={{
        authState: { ...authState, user: _user },
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin: isSuperAdmin,
        setImpersonate,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider };
