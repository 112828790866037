import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "../context/Internationalization";
import {Modal, ModalActions} from "./Modal";
import ModalTitle from "./Modal/ModalTitle";
import ModalBody from "./Modal/ModalBody";
import {Button} from "@mui/material";

const LogoutConfirmationModal = ({open, onClose, logout}) => {
    const {t} = useTranslation()

    return (
        <Modal open={open} onClose={onClose}>
            <ModalTitle title={t('logout')}/>
            <ModalBody>
                <span>{t('are_you_sure_you_want_to_logout')}<br/> </span>
            </ModalBody>
            <ModalActions>
                <Button variant={'outlined'} color={'primary'} fullWidth onClick={onClose}>{t('close')}</Button>
                <Button variant={'outlined'} color={'error'} fullWidth onClick={logout}>
                    {t('logout')}
                </Button>
            </ModalActions>

        </Modal>
    );
};

LogoutConfirmationModal.propTypes = {

};

export default LogoutConfirmationModal;
