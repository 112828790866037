import {makeStyles} from "@mui/styles";
import {colors} from '../../theme/colors'
import {fonts} from '../../theme/fonts'

const {Base, Primary, Grey} = colors
const {text} = fonts

const styles = {
    cardContainer: {
        background: Base.White,
        border: '1px solid #DADFEA',
        borderRadius: '8px',
        marginBottom: '16px',
        boxShadow: 'none',
        "&.MuiAccordion-root:before": {
            height: '0'
        }
    },
    addOption: {
        ...text.sm.normal,
        color: Primary[700],
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '4px'
    },
    requiredLabel: {
        ...text.md.normal,
        color: Grey[700],
    }
}

export default makeStyles(styles)
