import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {v4 as uuidv4} from "uuid";
import update from "immutability-helper";
import {axiosGraphQL} from "../../api";

import {prepareGroupFieldsForRequest} from "../../utility/Utils";
import {CREATE_FORM_DESCRIPTOR} from "./formService";


const initialState = {
    loading: false,
    form: {
        formName: '',
        formDescription: '',
        dueDate: null,
        submitter: 'profile',
        errors: {}
    },
    fields: [
        {
            id: uuidv4(),
            name: '',
            type: 'text',
            label: '',
            placeholder: '',
            required: true,
            validations:[{
                type: '',
                errorLabel: '',
                value: '',
            }],
            options: [{id: uuidv4()}]
        },
    ]
}

// AsyncThunk: createForm
export const createForm = createAsyncThunk(
    "forms/createForm",
    async (arg, { getState }) => {
        const forms = getState().forms;
        const form = forms.form
        const fields = forms.fields
        const variables = {
            formGroups: [
                {
                    label: '',
                    formFields: prepareGroupFieldsForRequest(fields)
                }
            ],
            formName: form.formName,
            formDescription: form.formDescription,
            dueDate: form.dueDate,
            club: arg.clubId,
            submitter: form.submitter
        }
        try {
            return await axiosGraphQL({query: CREATE_FORM_DESCRIPTOR, variables})
        } catch (error) {
            throw error;
        }
    }
);

export const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        addField: (state) => {
            const field =  {
                id: uuidv4(),
                name: '',
                type: 'text',
                label: '',
                placeholder: '',
                required: true,
                validations:[{
                    type: '',
                    errorLabel: '',
                    value: '',
                }],
                options: [{id: uuidv4()}]
            }

            state.fields = [...state.fields, field]
        },
        removeField: (state, action) => {
            const {fieldId} = action.payload

            state.fields =  state.fields.filter(fixture => fixture?.id !== fieldId)
        },
        copyField: (state, action) => {
            const {fieldId} = action.payload
            let atIndex = 0
            const findData = state.fields.find((data, index) => {
                atIndex = index
                return data.id === fieldId
            })

            if (findData) {
                let _copiedField = {...findData}

                _copiedField.id = uuidv4()
                state.fields = update([...state.fields], {
                    $splice: [
                        [state.fields.length + 1, 1],
                        [atIndex + 1, 0, _copiedField],
                    ],
                })
            }
        },
        moveField: (state, action) => {
            const card = state.fields.filter((c) => `${c.id}` === action.payload.id)[0]
            const index = state.fields.indexOf(card)

            state.fields = update(state.fields, {
                $splice: [
                    [index, 1],
                    [ action.payload.atIndex, 0, card],
                ],
            })
        },
        addOption: (state, action) => {

            const numberOfMatches = action.payload.numberOfMatches
            const matchesToAdd = []

            for(let i = 0; i < numberOfMatches; i ++) {
                matchesToAdd.push({
                    id: uuidv4(),
                    homeTeam: '',
                    awayTeam: '',
                    matchDate: null,
                    matchTime: null,
                    matchVenue: '',
                    matchStatus: '',
                    matchScore: ''
                })
            }

            state.fields = state.fields.map((c) => {
                if(`${c.id}` === action.payload.id) {
                    c.options = [...c.options, ...matchesToAdd]
                }
                return c
            })
        },
        removeOption: (state, action) => {
            const {fieldId, matchId} = action.payload

            state.fields = state.fields.map(fixture => {
                if(fixture?.id === fieldId) {
                    fixture.options = fixture.options.filter(match => match?.id !== matchId)
                }

                return fixture
            })
        },
        changeFieldName: (state, action) => {
            const {name, value, id} = action.payload
            state.fields = state.fields.map(field => {
                if(field.id === id) {
                    field[name] = value
                }

                return field
            })
        },
        changeFieldDetails: (state, action) => {
            const {name, value, fieldId} = action.payload

            state.fields = state.fields.map(field => {
                if(field?.id === fieldId) {
                    field[name] = value
                }

                return field
            })
        },
        changeFormDetails: (state, action) => {

            state.form[action.payload.name] = action.payload.value
        },
        setLoading: (state, action) => {

            state.loading = action.payload
        },
        resetData: (state) => {
            state.form = initialState.form
            state.fields = initialState.fields
        }
    },
})

// Action creators are generated for each case reducer function
export const { addField, removeField, copyField, changeFieldDetails, changeFormDetails, moveField, resetData, setLoading } = formsSlice.actions

export default formsSlice.reducer
