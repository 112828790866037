import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalActions} from "../../../Components/Modal";
import {Button} from "@mui/material";
import ModalTitle from "../../../Components/Modal/ModalTitle";
import ModalBody from "../../../Components/Modal/ModalBody";
import {useTranslation} from "../../../context/Internationalization"


const DeleteConfirmationModal = ({open, onClose, onDelete, activity}) => {
    const {t} = useTranslation()

    return (
        <Modal open={open} onClose={onClose}>
            <ModalTitle title={t('delete_post')}/>
            <ModalBody>
                <span>{t('delete_post_warning_msg')}<br/> {t('delete_activity_confirmation_subtitle')}</span>
            </ModalBody>
            <ModalActions>
                <Button variant={'outlined'} color={'primary'} fullWidth onClick={onClose}>{t('close')}</Button>
                <Button variant={'outlined'} color={'error'} fullWidth onClick={onDelete}>
                    {t('delete')}
                </Button>
            </ModalActions>

        </Modal>
    );
};

DeleteConfirmationModal.propTypes = {

};

export default DeleteConfirmationModal;
