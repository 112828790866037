import React from "react";
import { Grid, Box } from "@mui/material";

export const DrawerFormTitle = ({ className, actionText, text }) => {
  return (
    <Grid className={className} container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ textAlign: "center", margin: "10px 10px" }}>
            {actionText}
            <span style={{ color: "#00CCCC" }}> {text}</span>
          </p>
        </Box>
      </Grid>
    </Grid>
  );
};
export default DrawerFormTitle;
